import { GameDescriptionProps } from "../../types";


export const videoGamesList: GameDescriptionProps[] = [
    {
        title: "Death Squared",
        description: `Imagine a fun-filled puzzle adventure where teamwork is key! Death Squared invites you to guide cute, colorful robots through tricky, trap-laden levels. \n
        It’s all about cooperation and thinking on your feet to solve clever challenges and avoid devious obstacles together. \n
        Perfect for playing with friends or family, this game combines brain-teasing puzzles with a dash of humor and a lot of heart. Whether it's working in pairs or gathering a group for a party mode, every level offers new surprises and laughs. Ready to team up and take on the challenge? 🚀`,
        imageUrl: "deathSquared.jpg",
        category: "Family",
        isMultiplayer: true,
        isSplitScreen: true,
        nintendoLink: "https://www.nintendo.com/us/store/products/death-squared-switch/",
        playstationLink: "https://www.playstation.com/es-us/games/death-squared/",
        xboxLink: "https://www.xbox.com/en-us/games/store/Death-Squared/BS32QFXVPWBC"
    },
    {
        title: "Overcooked!  All you can eat",
        description: "Great friends and family game, where you can play on local or online to create the best and funny dishes as you go through all of the worlds from Overcooked 1 and 2, plus the secre levels. If you want to save the world from Giant Meatball monster or the Zombie Breads to just play casually for fun thisis a MUST HAVE game if you have kids.",
        imageUrl: "overcooked.jpg",
        category: "Family",
        isMultiplayer: true,
        isSplitScreen: true,
        nintendoLink: "https://www.nintendo.com/us/store/products/overcooked-all-you-can-eat-switch",
        playstationLink: "https://www.playstation.com/en-us/games/overcooked--all-you-can-eat/",
        xboxLink: "https://www.xbox.com/en-US/games/store/overcooked-all-you-can-eat/9PP2NCZ72C79"
    },
    {
        title: "Astro Bot",
        description: "Join Astro Bot, a cute robot, on a thrilling rescue mission! Explore colorful worlds, solve puzzles, and defeat tricky enemies. With your DualSense controller, you can reach out and grab objects, swing through the air, and even tickle your robotic friends! Get ready for a fun-filled adventure that's perfect for the whole family",
        imageUrl: "astro.jpeg",
        category: "Family",
        isMultiplayer: false,
        isSplitScreen: false,
        nintendoLink: "",
        playstationLink: "https://www.playstation.com/en-us/games/astro-bot/",
        xboxLink: ""
    },
    {
        title: "Ghostbusters: Spirits Unleashed",
        description: "Great Ghostbusters' game for fans and kids who enjoy catching ghosts. This game you can be the classical ghostbuster or a ghost!! You can play with friends to help you in multiplayer and it is cross-play. There is no split-screen but the kids enjoy the solo gameplay too.",
        imageUrl: "EGS_GhostbustersSpiritsUnleashed_IllFonic.png",
        category: "Action",
        isMultiplayer: true,
        isSplitScreen: false,
        nintendoLink: "https://www.nintendo.com/us/store/products/ghostbusters-spirits-unleashed-ecto-edition-switch",
        playstationLink: "https://www.playstation.com/en-us/games/ghostbusters-spirits-unleashed/",
        xboxLink: "https://www.xbox.com/en-US/games/store/ghostbusters-spirits-unleashed-ecto-edition/9PHJC0H6HPKD"
    },
];
