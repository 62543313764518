import { Box, Container, Typography } from "@mui/material";
import { GameDescription } from "../components/VideoGames";
import { videoGamesList } from "../components/VideoGames";
import { GameDescriptionProps } from '../types/GameDescriptionProps';

export const VideoGamesPage = () => {
    return (
        <Container maxWidth="lg" sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            padding: "20px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            marginTop: "7rem",
            marginBottom: "7rem",
            flexDirection: "column",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            gap: 3

        }}>
            <Typography variant="h4">Recommendations</Typography>
            <Typography variant="body1" >
                This is a section where we want to recommend games that we've played and think are fun to play with the family, and also the AAA games we all like.
            </Typography>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "start",
                flexWrap: "wrap",
                gap: "1rem",
            }}>
                {videoGamesList.map((game: GameDescriptionProps, index: number) => (
                    <GameDescription key={`${game.title}-${index}`} {...game} />
                ))
                }
            </Box>
        </Container>
    );
};;;
